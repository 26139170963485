body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-text1 {
  /* Fallback: Set a background color. */
  background-color: red;
  
  /* Create the gradient. */
  background-image: linear-gradient(90deg, rgb(81, 0, 128), rgb(255, 0, 170));
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-text2 {
  /* Fallback: Set a background color. */
  background-color: red;
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, rgb(47, 25, 241), rgb(0, 162, 255));
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.gradient-text3 {
  /* Fallback: Set a background color. */
  background-color: red;
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, rgb(235, 135, 218), rgb(0, 68, 255));
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.emailField {
  text-align: center; 
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

.form-signin {
  display: flex;
  height:75vh;
  justify-content: center;
  align-items:center;
}

.signin-form {
  width:400px;
}

.thanks {
  max-height: 30vh;
}

.table-column-1 {
  width:34%;
}

.table-column-2 {
  width:22%
}

.compare-pricing-ticks {
  color: green;
}

.feature-div {
  margin-bottom: 8rem;
  margin-top:8rem;
}

.thanks-spacer {
  height:10.3vh
}

.footer-links {
  text-decoration: none;
  color: black;
}

.footer-links:hover{
  color:black;
  text-decoration: underline;
}

.underline {
  float: left;
  display:block;
  color: black ;
  text-align: center;
 
  text-decoration: none;
}

.home-page-highlight {
  background-color: #009577cc;
}

.card-img-top {
  width: 40%;
  height: auto;
  object-fit: cover;
  align-self: center;
}

#startNowButton {
  border-radius: 10px;
}

